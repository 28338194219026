










































































































import {
  defineComponent,
  useContext,
  ref,
  onMounted,
  useRoute,
  useRouter,
} from "@nuxtjs/composition-api";
import type { Breadcrumb } from "~/modules/catalog/types";
import Breadcrumbs from "~/components/Layouts/Breadcrumbs/index.vue";
import { useContent } from "~/composables";

import { getMetaInfo } from "~/helpers/getMetaInfo";

const DEFAULT_HELP_TOPICS = [
  {
    title: "topic 1",
    subtopics: [
      {
        id: 1,
        title: "subtopic 1",
        image: "defaults/layout-subcategories-image.png",
        items: [
          {
            contentType: "paragraph",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            contentType: "paragraph",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            contentType: "image",
            content: "defaults/layout-subcategories-image.png",
          },
          {
            contentType: "paragraph",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            contentType: "paragraph",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
      },
      {
        id: 2,
        title: "subtopic 2",
        items: [],
      },
      {
        id: 3,
        title: "subtopic 3",
        items: [],
      },
    ],
  },
  {
    title: "topic 2",
    subtopics: [
      {
        id: 4,
        title: "subtopic 1",
        items: [],
      },
      {
        id: 5,
        title: "subtopic 2",
        items: [],
      },
      {
        id: 6,
        title: "subtopic 3",
        items: [],
      },
    ],
  },
  {
    title: "topic 3",
    subtopics: [
      {
        id: 7,
        title: "subtopic 1",
        items: [],
      },
      {
        id: 8,
        title: "subtopic 2",
        items: [],
      },
      {
        id: 9,
        title: "subtopic 3",
        items: [],
      },
    ],
  },
];

const HELP_CENTER_PAGE_HEAD = {
  meta_title: "Modern Squared | Help Center",
  meta_description: "This is the help center page of Modern Squared",
};

const pageMeta = getMetaInfo(HELP_CENTER_PAGE_HEAD);

export interface Topic {
  id?: number;
  title: string;
  is_displayed?: boolean;
  image?: string;
  subtopics?: Array<Subtopic>;
}

export interface Subtopic {
  id: number;
  title: string;
  items: Array<{
    contentType: string;
    content: any;
  }>;
  image?: string;
}

export default defineComponent({
  components: {
    Breadcrumbs,
  },

  setup() {
    const { loadHelpCenter } = useContent();

    const { localePath } = useContext();

    const route = useRoute();
    const router = useRouter();

    const refSubtopic = ref(null);
    const pageTitle = ref("help center");

    const DEFAULT_HEADER_IMAGE = {
      src: "defaults/home-hero-image.png",
      alt: "Help center page.",
      tag: "Help center main image",
    };

    const pageHeaderImage = ref(DEFAULT_HEADER_IMAGE);

    const breadcrumbs = ref<Breadcrumb[]>([
      {
        text: pageTitle.value,
        link: localePath("/customer-service/help-center"),
      },
    ]);
    const prefixBreadcrumbs = ref<Breadcrumb[]>([
      {
        text: "home",
        link: localePath("/"),
      },
    ]);

    const helpTopics = ref<Topic[]>([]);

    const currentSubtopic = ref<Subtopic>({ id: null, title: "", items: [] });

    const currentTopicIndex = ref<number>(null);

    const expandTopic = (topicIndex: number) => {
      currentTopicIndex.value = topicIndex;
    };

    const expandSubtopic = (subtopic: Subtopic) => {
      currentSubtopic.value = subtopic;
      if (!!currentSubtopic.value.image) {
        pageHeaderImage.value.src = currentSubtopic.value.image;
      } else {
        pageHeaderImage.value = DEFAULT_HEADER_IMAGE;
      }
      refSubtopic.value.scrollIntoView({ behavior: "smooth" });
    };

    const loadTopics = async () => {
      const loadedTopics = await loadHelpCenter();
      if (loadedTopics.length === 0) {
        helpTopics.value = DEFAULT_HELP_TOPICS;
      } else {
        helpTopics.value = loadedTopics;
      }
    };

    const loadCurrentTopic = () => {
      if (helpTopics.value.length > 0) {
        if (helpTopics.value[0].subtopics.length > 0) {
          expandTopic(0);
          expandSubtopic(helpTopics.value[0].subtopics[0]);
        }
      }
    };

    onMounted(async () => {
      await loadTopics();
      loadCurrentTopic();
    });

    return {
      pageHeaderImage,
      pageTitle,
      prefixBreadcrumbs,
      breadcrumbs,
      helpTopics,
      expandTopic,
      expandSubtopic,
      currentSubtopic,
      currentTopicIndex,
      refSubtopic,
    };
  },
  head() {
    return {
      ...pageMeta,
    };
  },
});
